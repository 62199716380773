// HeroSection.js
import React from 'react';
import BookCover from './img/blockchain_tethered_ai_cover_color.jpg'


const HeroSection2 = () => (
    <div className="content">
        <div className={"content-hero"}>
            <img className={"leftImageFull"} src={BookCover} alt={"Blockchain Tethered AI by Karen Kilroy, Lynn Riley, et al book cover"} />
            <h3>Blockchain Tethered AI</h3>
            <p>Authors: Karen Kilroy, Lynn Riley, et al.</p>
            <p>ISBN-13: 9781098130480</p>
            <p>Publisher: O'Reilly Media, Incorporated</p>
            <p>Publication date: 02/2023</p>
            <p>Pages: 270</p>
            <p>Product dimensions: 7.00(w) x 9.19(h) x 0.00(d)</p>

            <p>*Blockchain Tethered AI is also distributed by Shroff Publishers & Distributors in the the Indian Subcontinent, including India, Pakistan, Bangladesh, Nepal, Sri Lanka. Bhutan. Maldives) and African Continent (excluding Morocco, Algeria, Tunisia, Libya, Egypt, and the Republic of South Africa).</p>
        </div>

        <div>
            <p>
                <a id="2961775836" href="https://goto.walmart.com/c/3836723/1285455/9383?prodsku=2961775836&amp;u=https%3A%2F%2Fwww.walmart.com%2Fip%2FBlockchain-Tethered-AI-Trackable-Traceable-Artificial-Intelligence-and-Machine-Learning-Paperback-9781098130480%2F2961775836&amp;intsrc=PUI2_9781" target="_top">
                </a>
            </p>
            <p>
                <a className="btn btn-primary-buy button-banner walmart" href="https://goto.walmart.com/c/3836723/565706/9383?veh=aff&sourceid=imp_000011112222333344&u=https%3A%2F%2Fwww.walmart.com%2Fip%2FBlockchain-Tethered-AI-Trackable-Traceable-Artificial-Intelligence-and-Machine-Learning-Paperback-9781098130480%2F2961775836&intsrc=PRODUCT_UI_V2">Buy the Book on Walmart.com &#8594;</a>
            </p>
            <p>
                <a className="btn btn-primary-buy button-banner" href="https://www.barnesandnoble.com/w/blockchain-tethered-ai-karen-kilroy/1142613946?ean=9781098130480">Buy the Book on Barnes & Noble &#8594;</a>
            </p>
            <p>
                <a className="btn btn-primary-buy button-banner shroff" href="https://www.shroffpublishers.com/books/9789355429865/">Indian and African Subcontinents: Buy the Book on Shroff* &#8594;</a>
            </p>


            <div className="parallax">&nbsp;</div>


    </div>
    </div>

);
export default HeroSection2;