// App.js
import React from 'react';
import './App.css';
import Footer from "./Footer";
import Hero from "./HeroSection1";
import Hero2 from "./HeroSection2";

function App() {
    return (
        <div className="App">
            <Hero />
            <Hero2 />
            <Footer />
        </div>
    );
}

export default App;