// Footer.js
import React from 'react';
const Footer = () => (
    <section className="footer">
        <div className={"footer"}>

                &copy;Copyright 2024, Karen Kilroy All Rights Reserved


        </div>
    </section>
);

export default Footer;


