// HeroSection.js
import React from 'react';
import ColorBookPhoto from './img/Blockchain Tethered AI book color photograph by Karen Kilroy.jpg';
import Killdeer from './img/killdeer-color.png';
import Karen from './img/karen_kilroy.png';
import Lynn from './img/lynn_riley.png';

const HeroSection1 = () => (
    <div className={"content"}>
    <div className={"content-hero"}>
        <div className={"banner"}>
        <h1 className="booktitle">Blockchain Tethered AI</h1>
    <h2>Trackable, Traceable Artificial Intelligence and Machine Learning</h2>
        </div>
<div>
    <a className="btn btn-primary button-banner" href="https://learning.oreilly.com/library/view/blockchain-tethered-ai/9781098130541/">See the Book on O'Reilly</a></div>
<div>
    <a className="btn btn-primary-buy button-banner" href="https://amzn.to/3gGKr1C">Buy the Book on Amazon &#8594;</a></div>

    </div>
        <div className={"hero-img"}>
        <img src={ColorBookPhoto} className={"centeredImage"} alt={"Blockchain Tethered AI goes well with your morning brew, pick up a copy today from your favorite bookseller."}/>
            <p>Enjoy your favorite beverage in your best mug while you dive into your own copy of Blockchain Tethered AI. Available from booksellers and libraries everywhere. Original photograph by Karen Kilroy.</p>
        <div>
    <div>

        <h3>About the Book</h3>

        <p>Artificial intelligence exists as an opaque box, meaning nobody is quite sure what is inside, and we have to just trust the AI in order to use it. Even the engineers who develop the AI don't know exactly what is there. Meanwhile, AI's ability to change itself through program synthesis and genetic algorithms could rapidly take the technology beyond human control. With this book, you'll learn an efficient way to proactively solve this problem by building simple blockchain controls for verifying, tracking, tracing, auditing, and even reversing AI. The techniques used are similar to track and trace methods used in a supply chain.</p>
                                </div>
            <img className="killdeer" src={Killdeer} alt={"Killdeer bird"}/>
        </div>

            <div className="parallax">&nbsp;</div>
                                    <h3>What Readers are Saying</h3>
                                    <blockquote>"AI is a superhero toddler. Without meaning harm, its laser eyes could reduce a city to ashes. The solution? Make sure the toddler does not have a tantrum in Times Square. This hands-on book describes this solution precisely and pragmatically."</blockquote>
    <p>–Jean-Georges "jpg" Perrin, Intelligence Platform Lead, PayPal and Lifetime IBM Champion</p>
    <blockquote>"The field of AI continues to march on regardless of the consequences. This thoughtful book explains how to balance innovative AI with checks and measures in order to both explain how AI got its answers and to tether it using blockchain to ensure that you get your desired results. Very little in this field has been developed, and this solid developer-focused treatise will have you up to your arms in code that will tame your AI and stop Skynet! Miss this read at your peril."
    </blockquote>
    <p>–Mandip (Mans)  Bhuller, Public Cloud Expert and Technology Visionary
    </p>
    <blockquote>"This book demystifies the advanced technologies of AI, ML, and blockchain, immersing the reader in an interactive exercise you can build on using these technologies. It was an absolute pleasure being one of the first readers of this highly valuable material."
    </blockquote>
    <p>–Tommy Cooksey III, Blockchain & Cloud Architect</p>

<div>

    <br/>
    <br/>
    <br/>
    <div>
        <h2>About the Authors</h2>

        <div id="team">
                 <div className="team">
                    <div>
                        <div className={"teammember"}>
                            <div className="block-team">
                                <img className="headshot" src={Karen} alt="Karen Kilroy" />
                                    <h3>Karen Kilroy</h3>
                                <a href="https://linkedin.com/in/karenkilroy/" target="_blank" rel="noreferrer">LinkedIn</a>

                                <p>Karen Kilroy is a life-long technologist with heart. CEO of <a href={"https://file.baby"}>File Baby</a> and <a href={"https://kilroyblockchain.com"}>Kilroy Blockchain</a> and Co-Founder of AI Research Lab <a href={"https://friendsofjustin.60sec.site"}>Friends of Justin</a>, Karen is a five-time <a href={"https://community.ibm.com/community/user/champions/blogs/libby-ingrassia/2024/01/18/congratulations-and-welcome-to-the-ibm-champions-c"}>IBM Champion</a>. Karen began programming computers at the age of 12 on a <a href={"https://d1yx3ys82bpsa0.cloudfront.net/brochures/radioshack.trs80.1977.102646108.pdf"}>Radio Shack TRS-80</a>, and has since led many development teams. Karen still does hands-on full-stack coding and system administration today, focusing on applications that do good in the world and help others.</p>
                                <p>Karen's career led her from office automation to help desk and network support; to enterprise email administration and workflow systems; to ecommerce web development, digital art, videography and online marketing. This led to web application development based on blockchain and AI.
                                </p>
                                <p>Karen makes her knowledge available to others through her books and reports, published under the popular <a href={"https://www.oreilly.com/people/karen-kilroy/"}>O'Reilly</a> brand. Besides <a href={"https://learning.oreilly.com/library/view/blockchain-tethered-ai/9781098130541/"}>Blockchain Tethered AI</a> (2023), they include <a href={"https://learning.oreilly.com/library/view/natural-language-and/9781098156268/"}>Natural Language and Search</a> (2024), <a href={"https://learning.oreilly.com/library/view/ai-and-the/9781492091837/"}>AI and the Law</a> (2021) and <a href={"https://learning.oreilly.com/library/view/blockchain-as-a/9781492073475/"}>Blockchain-as-a-Service</a> (2019). Karen has also compiled an <a href={"https://learning.oreilly.com/playlists/b961e90f-1fdd-4e3b-b7d8-a5ba197951b2"}>Expert Playlist on AI</a> and written a blog for O'Reilly, <a href={"https://www.oreilly.com/radar/ais-opaque-box-is-actually-a-supply-chain/"}>AI's Opaque Box is Actually a Supply Chain</a>. She can frequently be found speaking at events, both local and global, online and in person.</p>

                            </div>
                        </div>

                        <div>
                            <div className={"teammember"}>
                                <img className="headshot" src={Lynn} alt="Lynn Riley" />
                                    <h3>Lynn Riley</h3>
                                    <a href="https://linkedin.com/in/lynncmriley/" target="_blank" rel="noreferrer">
                                        LinkedIn
                                    </a>
                                <p>Lynn Riley, a graduate of MIT in chemical engineering, has worked at Motorola, Apple, and CORT Business Services and is Kilroy Blockchain’s CIO. Residing in Austin, Texas, Lynn is instrumental to Kilroy Blockchain’s AI and machine learning development and trained <a href={"https://kilroyblockchain.com/riley"}>RILEY</a>, Kilroy Blockchain’s AI app that won the IBM Watson Build award for North America in 2017.</p>
                                <p>Lynn specializes in blockchain and AI governance and helps guide Kilroy Blockchain’s customers with application architecture in the design thinking stage of development. Lynn has been instrumental to the workflow design of Kilroy Blockchain’s products and spent most of 2018 traveling with Karen Kilroy on a National Science Foundation research project focused on autonomous vehicles, which resulted in the <a href={"https://kilroyblockchain.com/carnak"}>CARNAK</a> product. From 2019 to 2021, she was the Industry/Government Chairperson for the <a href={"https://tapiaconference.cmd-it.org"}>CMD-IT/ACM Richard Tapia Conference for Diversity in Computing</a>. Lynn is also a tech reviewer for O’Reilly.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>

<div className="parallax">&nbsp;</div>

    </div>
    </div>


);
export default HeroSection1;